import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { css } from "@emotion/react";
import theme from "@/styles/themes/theme";

export const styles = {
  carouselnavigation__dot: css`
    background-color: rgba(0, 56, 99, 0.5);
    border-radius: 100%;
    &:hover {
      background-color: ${theme.palette.primary.main};
    }
    min-width: 16px;
    min-height: 16px;
    margin-right: 8px;
    margin-left: 8px;
    padding: 0;
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      min-width: 8px;
      min-height: 8px;
      margin-right: 4px;
      margin-left: 4px;
    }
  `,
  carouselnavigation__dot_active: css`
    background-color: #003863;
  `
};

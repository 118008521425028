import React from "react";

import { LinkUtils } from "@/utils/linkUtils";
import { Card, CardActionArea, CardMedia } from "@mui/material";

import { styles } from "./styles";
import { CarouselLogosCardProps } from "./types";

export const CarouselLogosCard: React.FC<CarouselLogosCardProps> = ({ image, title, url }) => {
  const handleClick = () => {
    LinkUtils.openLink(url);
  };

  return (
    <Card css={styles.carousellogoscard}>
      <CardActionArea css={styles.carousellogoscard__button} onClick={handleClick}>
        <CardMedia css={styles.carousellogoscard__media} image={image} title={title ?? ""} />
      </CardActionArea>
    </Card>
  );
};

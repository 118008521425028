import React from "react";

import { Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Stack, Typography } from "@mui/material";

import { styles } from "./styles";
import { PartnerCardProps } from "./types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";

export const PartnerCard: React.FC<PartnerCardProps> = ({
  title,
  description,
  tags,
  productCoinValueText,
  image,
  additionalKmPoints,
  valueWithPoints,
  valueWithoutPoints,
  showDiscountSection,
  children,
  sx,
  cardMediaProps,
  onClick
}) => {
  return (
    <Card css={styles.partnercard} sx={sx}>
      <Stack css={styles.partnercard__stack}>
        <CardActionArea onClick={onClick} css={styles.partnercard__button}>
          <CardMedia sx={{ height: 140 }} image={image ?? "/placeholder.png"} title={title} css={styles.partnercard_media} {...cardMediaProps} />

          <CardContent css={styles.partnercard__content}>
            <Typography gutterBottom variant="h6" component="div" css={styles.partnercard__title} title={title}>
              {title}
            </Typography>

            <Typography variant="body2" component="div" css={styles.partnercard__description}>
              {typeof description === "object" ? documentToReactComponents(description as Document) : description}
            </Typography>
          </CardContent>

          <CardContent sx={{ py: 0, px: 1 }}>
            {showDiscountSection ? (
              <Stack>
                <Typography variant="body2" css={styles.partnercard__oldprice}>
                  de {valueWithoutPoints}
                </Typography>

                <Typography variant="body2" css={styles.partnercard__newprice}>
                  {valueWithPoints}

                  {additionalKmPoints ? (
                    <Typography component="span" css={styles.partnercard__kms}>
                      {additionalKmPoints}
                    </Typography>
                  ) : null}
                </Typography>
              </Stack>
            ) : null}

            {productCoinValueText ? (
              <Typography variant="body2" css={styles.partnercard__points}>
                {productCoinValueText}
              </Typography>
            ) : null}

            <Stack gap={1} py={1} display="flex" flexWrap="wrap" direction="row">
              {tags?.map((tag) => (
                <Chip key={tag} label={tag} color="primary" css={styles.partnercard__chip} />
              ))}
            </Stack>
          </CardContent>
        </CardActionArea>

        <CardActions css={styles.partnercard__actions}>{children}</CardActions>
      </Stack>
    </Card>
  );
};

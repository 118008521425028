/* eslint-disable react/display-name */
import { forwardRef, useImperativeHandle, useState } from "react";

import { Box, Button, Grid, Tooltip } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";

import { styles } from "./styles";
import { CarouselNavigationProps, CarouselNavigationRef } from "./types";

export const CarouselNavigation = forwardRef<CarouselNavigationRef, CarouselNavigationProps>((props, ref) => {
  const { onNextClick, onPreviousClick, onDotClick, currentSlide, slidesCount, ...rest } = props;
  const [disabled, setDisabled] = useState({ disableNext: false, disablePrevious: false });

  const handleDotClick = (index: number) => () => {
    if (onDotClick) {
      onDotClick(index);
    }
  };

  useImperativeHandle(ref, () => ({
    changeNavigation: (previousState: boolean, nextState: boolean) => {
      setDisabled({ disableNext: nextState, disablePrevious: previousState });
    }
  }));

  return (
    <Box {...rest}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Tooltip title="Anterior">
            <Button
              onClick={onPreviousClick}
              aria-label="Anterior"
              color="inherit"
              variant="outlined"
              sx={{ minHeight: 44, minWidth: 44, p: 0, borderRadius: "100%" }}
              disabled={disabled?.disablePrevious}
            >
              <ArrowLeft size={22} />
            </Button>
          </Tooltip>
        </Grid>

        {slidesCount ? (
          <Grid item display="flex" justifyContent="center" alignItems="center">
            {Array.from({ length: slidesCount }).map((_, index) => (
              <Button
                onClick={handleDotClick(index)}
                key={index}
                css={[styles.carouselnavigation__dot, currentSlide === index && styles.carouselnavigation__dot_active]}
              />
            ))}
          </Grid>
        ) : null}

        <Grid item>
          <Tooltip title="Próximo">
            <Button
              onClick={onNextClick}
              aria-label="Próximo"
              color="inherit"
              variant="outlined"
              sx={{ minHeight: 44, minWidth: 44, p: 0, borderRadius: "100%" }}
              disabled={disabled?.disableNext}
            >
              <ArrowRight size={22} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
});

export interface CarouselState {
  animating: boolean;
  autoplaying: string;
  currentDirection: number;
  currentLeft: unknown;
  currentSlide: number;
  direction: number;
  dragging: boolean;
  edgeDragged: boolean;
  initialized: boolean;
  lazyLoadedList: number[];
  listHeight: number;
  listWidth: number;
  scrolling: boolean;
  slideCount: number;
  slideHeight: number;
  slideWidth: number;
  swipeLeft: unknown;
  swiped: boolean;
  swiping: boolean;
  touchObject: { startX: number; startY: number; curX: number; curY: number };
  trackStyle: {
    opacity: number;
    transition: string;
    WebkitTransition: string;
    WebkitTransform: string;
    transform: string;
    msTransform: string;
    width: number;
  };
  trackWidth: number;
  targetSlide: number;
}

/**
 * Get current visible items count
 */
const getVisibleItemsCount = ({ listWidth, slideWidth }: Pick<CarouselState, "listWidth" | "slideWidth">) => {
  try {
    /* Get visible items count */
    const visibleItems = Math.round(listWidth / slideWidth);

    return visibleItems;
  } catch (error) {
    return 0;
  }
};

/**
 * Checks if carousel navigation buttons are disabled
 */
const getNavigationState = ({ nextSlide, carouselState }: { nextSlide?: number; carouselState: CarouselState }) => {
  try {
    /* Get visible items count */
    const visibleItems = getVisibleItemsCount({ listWidth: carouselState.listWidth, slideWidth: carouselState.slideWidth });
    const slideCount = carouselState.slideCount;

    let enableNext = false;

    if (nextSlide !== undefined) {
      enableNext = slideCount - nextSlide > visibleItems;
    } else if (slideCount > visibleItems) {
      enableNext = true;
    }

    return {
      disableNext: !enableNext,
      disablePrevious: nextSlide === 0 // || carouselState.currentSlide === 0
    };
  } catch (error) {
    return {
      disableNext: false,
      disablePrevious: false
    };
  }
};

export const CarolselUtils = {
  getNavigationState,
  getVisibleItemsCount
};

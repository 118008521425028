import Image from "next/image";

import { LinkUtils } from "@/utils/linkUtils";
import { Box } from "@mui/material";

import { styles } from "./styles";
import { CarouselMainItemProps } from "./types";

export const CarouselMainItem: React.FC<CarouselMainItemProps> = ({ image, url }) => {
  return (
    <Box
      css={styles.carouselmainitem}
      onClick={() => {
        LinkUtils.openLink(url);
      }}
    >
      <Image src={image} alt="Imagem do carrossel de mega-banners" css={styles.carouselmainimage} width={1920} height={1080} />
    </Box>
  );
};

import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  partnercard: css`
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    /* max-width: 345px; */
    /* min-width: 260px; */
    border-radius: ${theme.spacing(2)};
    height: 100%;
  `,
  partnercard__stack: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: inherit;
    justify-content: space-between;
  `,
  partnercard__button: css`
    display: flex;
    flex-direction: column;
    align-items: inherit;
    flex: 1;
  `,
  partnercard_media: css`
    position: relative;
    width: 100%;
    padding-bottom: 66.67%;
    overflow: hidden;
  `,
  partnercard__content: css`
    flex: 1;
  `,
  partnercard__title: css`
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  `,
  partnercard__description: css`
    color: rgba(0, 56, 99, 0.7);
    text-transform: none;
    white-space: pre-line;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    ul,
    ol {
      padding-inline-start: 18px;
    }
  `,
  partnercard__chip: css`
    color: #000000;
  `,
  partnercard__oldprice: css`
    text-decoration: line-through;
    color: ${theme.palette.text.secondary};
  `,
  partnercard__newprice: css`
    color: ${theme.palette.text.secondary};
  `,
  partnercard__kms: css`
    color: ${theme.palette.info.main};
  `,
  partnercard__x: css`
    color: ${theme.palette.primary.main};
  `,
  partnercard__points: css`
    color: ${theme.palette.info.main};
    font-weight: 600;
    font-size: inherit;
    margin-left: 8px;
  `,
  partnercard__actions: css`
    padding-bottom: ${theme.spacing(2)};
  `
};

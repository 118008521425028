/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";

import { useShoppingCart } from "@/context/ShoppingCartContext";
import { Button, CircularProgress } from "@mui/material";

import { LinkUtils } from "@/utils/linkUtils";
import { CartButtonProps } from "./type";
import { useLoader } from "@/hooks/utilHooks";

const TitleFromType: Record<string, string> = {
  RESGATE: "RESGATAR VOUCHER",
  SITE: "IR PARA O SITE",
  INFORMATIVO: "TROQUE SEUS PONTOS KMV"
};

export const CartButton: React.FC<CartButtonProps> = ({ title, buttonType, categoryId, partnerId, productId, buttonUrl, cardUrl, buttonProps }) => {
  const { addItemToCart } = useShoppingCart();
  const { hideLoader, loading, showLoader } = useLoader();

  const handleClick = () => {
    if (buttonType === "RESGATE") {
      showLoader();
      addItemToCart({ categoryId, partnerId, productId }).finally(hideLoader);
    } else {
      LinkUtils.openLink(buttonUrl ?? cardUrl ?? "");
    }
  };

  return (
    <Button fullWidth variant="contained" color="warning" onClick={handleClick} sx={{ fontSize: 12 }} disabled={loading} {...buttonProps}>
      {title ?? TitleFromType[buttonType ?? ""]}

      {loading ? <CircularProgress size="small" sx={{ width: 24, height: 24, ml: 2 }} color="secondary" /> : null}
    </Button>
  );
};

import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  footballcard: css`
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    /* max-width: 345; */
    border-radius: ${theme.spacing(2)};
    height: 100%;
  `,
  footballcard__stack: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: inherit;
    justify-content: space-between;
  `,
  footballcard__button: css`
    display: flex;
    flex-direction: column;
    align-items: inherit;
    flex: 1;
  `,
  footballcard__content: css`
    flex: 1;
  `,
  footballcard__image: css`
    height: 80px;
    width: 80px;
    object-fit: contain;
    background-size: contain;
  `,
  footballcard__title: css`
    font-weight: 500;
  `,
  footballcard__description: css`
    color: rgba(0, 56, 99, 0.5);
    text-transform: none;
  `,
  footballcard__chip: css`
    color: #000000;
  `,
  footballcard__oldprice: css`
    text-decoration: line-through;
    color: ${theme.palette.text.secondary};
  `,
  footballcard__newprice: css`
    color: ${theme.palette.text.secondary};
  `,
  footballcard__kms: css`
    color: ${theme.palette.info.main};
    font-weight: 600;
    font-size: inherit;
  `,
  footballcard__x: css`
    color: ${theme.palette.primary.main};
  `,
  footballcard__datetime: css`
    color: ${theme.palette.info.main};
    margin-top: ${theme.spacing(2)};
    font-weight: 600;
  `,
  footballcard__actions: css`
    padding-bottom: ${theme.spacing(2)};
  `
};

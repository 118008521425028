import React from "react";
import { Stack, Typography } from "@mui/material";
import { ContainerHeaderProps } from "./types";
import theme from "@/styles/themes/theme";

export const ContainerHeader: React.FC<ContainerHeaderProps> = ({ children, subtitle, title, ...rest }) => {
  return (
    <Stack mb={2} direction="row" alignItems="flex-end" {...rest}>
      <Stack flex={1}>
        <Typography variant="h5" fontWeight={400} textTransform="uppercase">
          {title}
        </Typography>

        <Typography variant="h5" color={theme.palette.info.main} fontWeight={600} textTransform="uppercase">
          {subtitle}
        </Typography>
      </Stack>

      {children}
    </Stack>
  );
};

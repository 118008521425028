import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  carouselmain: css`
    .slick-slide > div {
      margin: 8px;
      @media (max-width: ${theme.breakpoints.values.sm}px) {
        margin: 4px;
      }
      overflow: hidden;
      transition: all 0.8s;
    }
    .slick-slide:not(.slick-center) > div {
      transition: all 0.8s;
      opacity: 0.4;
    }
  `
};

export const carouselMainResponsiveness = [
  {
    breakpoint: theme.breakpoints.values.xs,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: true,
      centerMode: true,
      centerPadding: "10%"
    }
  },
  {
    breakpoint: theme.breakpoints.values.sm,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: true,
      centerMode: true,
      centerPadding: "10%"
    }
  },
  {
    breakpoint: theme.breakpoints.values.md,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: true,
      infinite: true,
      centerMode: true,
      centerPadding: "25%"
    }
  },
  {
    breakpoint: theme.breakpoints.values.lg,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true
    }
  },
  {
    breakpoint: theme.breakpoints.values.xl,
    settings: {
      centerMode: true,
      centerPadding: "25%",
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true
    }
  }
];

import "swiper/css";
import "swiper/css/pagination";

import { useState } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import { ArrowButton } from "@/components/Buttons/ArrowButton";

import { CarouselProps } from "./types";

export const Carousel = <T,>({ itens, showNavigation = true, renderItem, slidesPerView = "auto" }: CarouselProps<T>) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
  const [disableBackButton, setDisableBackButton] = useState(true);
  const [disableNextButton, setDisableNextButton] = useState(false);

  const handleNext = () => {
    if (!swiperRef) {
      return;
    }

    swiperRef.slideNext();
  };

  const handleBack = () => {
    if (!swiperRef) {
      return;
    }

    swiperRef.slidePrev();
  };

  const onChange = (swiper: SwiperClass) => {
    if (!swiper.isBeginning && !swiper.isEnd) {
      setDisableBackButton(false);
      setDisableNextButton(false);
    }
    if (!swiper.isBeginning) {
      setDisableBackButton(false);
    }
    if (!swiper.isEnd) {
      setDisableNextButton(false);
    }
  };
  return (
    <>
      <Swiper
        onSwiper={setSwiperRef}
        pagination={
          showNavigation
            ? false
            : {
                clickable: true
              }
        }
        modules={[Pagination]}
        slidesPerView={slidesPerView}
        onSlideChange={onChange}
        updateOnWindowResize={true}
        onResize={onChange}
        onEnded={() => {
          setDisableNextButton(true);
        }}
        onReachBeginning={() => {
          setDisableBackButton(true);
        }}
        onReachEnd={() => {
          setDisableNextButton(true);
        }}
      >
        {itens?.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              height: "auto",
              minWidth: "282px",
              marginRight: 24,
              alignItems: "stretch",
              display: "flex"
            }}
          >
            {renderItem(item, index)}
          </SwiperSlide>
        ))}
      </Swiper>

      {showNavigation ? (
        <ArrowButton
          handleNext={handleNext}
          handleBack={handleBack}
          disableBackButton={disableBackButton}
          disableNextButton={disableNextButton}
          style={{ width: 52, height: 52, marginTop: "20px" }}
        />
      ) : null}
    </>
  );
};

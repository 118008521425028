import { css } from "@emotion/react";

export const styles = {
  carouselmainitem: css`
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    cursor: pointer;
  `,
  carouselmainimage: css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  `
};

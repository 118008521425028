import { css } from "@emotion/react";

export const styles = {
  button: css`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #0045b5;
    color: white;

    &:hover {
      background-color: #003399;
    }
    &:disabled {
      background-color: #ccdaf0;
    }
  `
};

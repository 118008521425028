/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef } from "react";
import Slider from "react-slick";

import { ContainerHeader } from "@/components/Container/ContainerHeader";
import { CarolselUtils } from "@/utils/carousel";

import { CarouselNavigation } from "../CarouselNavigation";
import { CarouselLogosCard } from "./CarouselLogosCard";
import { carouselLogosResponsiveness, styles } from "./styles";
import { CarouselLogosProps } from "./types";
import { CarouselNavigationRef } from "../CarouselNavigation/types";

export const CarouselLogos: React.FC<CarouselLogosProps> = ({ title, subtitle, items }) => {
  const sliderRef = useRef<Slider | null>(null);
  const navigationRef = useRef<CarouselNavigationRef>(null);

  const handlePrevious = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <>
      <ContainerHeader title={title} subtitle={subtitle}>
        <CarouselNavigation onNextClick={handleNext} onPreviousClick={handlePrevious} justifyContent="flex-end" ref={navigationRef} />
      </ContainerHeader>

      {/* @ts-ignore */}
      <Slider
        centerMode={false}
        dots={false}
        slidesToShow={6}
        slidesToScroll={1}
        swipe={true}
        focusOnSelect={false}
        css={styles.carousellogos}
        adaptiveHeight={false}
        useTransform={true}
        arrows={false}
        infinite={false}
        speed={300}
        ref={sliderRef}
        initialSlide={0}
        responsive={carouselLogosResponsiveness}
        onReInit={() => {
          try {
            if (!sliderRef.current?.innerSlider) {
              return;
            }

            /* @ts-ignore */
            const carouselState = sliderRef.current.innerSlider.state as CarouselState;

            const { disableNext, disablePrevious } = CarolselUtils.getNavigationState({ carouselState, nextSlide: carouselState?.targetSlide });

            if (!navigationRef.current) {
              return;
            }

            navigationRef.current.changeNavigation(disablePrevious, disableNext);
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {items?.map((props, index) => (
          <CarouselLogosCard key={index} {...props} />
        ))}
      </Slider>
    </>
  );
};

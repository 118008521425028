import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  carousellogos: css`
    .slick-slide {
      height: auto;
      padding: 4px 8px 8px;
      @media (max-width: ${theme.breakpoints.values.sm}px) {
        padding: 4px 4px 8px;
      }
      box-sizing: border-box;
    }
    .slick-slide:not(.slick-current) {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        padding: 4px 8px 8px;
      }
    }
    .slick-slide > div {
      height: 100%;
    }
    .slick-track {
      display: flex;
    }
  `
};

export const carouselLogosResponsiveness = [
  {
    breakpoint: theme.breakpoints.values.xs,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: true,
      centerMode: false
    }
  },
  {
    breakpoint: theme.breakpoints.values.sm,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: true,
      centerMode: false
    }
  },
  {
    breakpoint: theme.breakpoints.values.md,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      swipe: true,
      infinite: true
    }
  },
  {
    breakpoint: theme.breakpoints.values.lg,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true
    }
  },
  {
    breakpoint: theme.breakpoints.values.xl,
    settings: {
      centerMode: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: true
    }
  }
];

import { ReactElement } from "react";

import { Tooltip } from "@mui/material";

export const TooltipCustom = ({ title, children }: { title: string; children: ReactElement }) => {
  return (
    <Tooltip
      title={title}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            color: "white",
            bgcolor: "#00ADFF",
            fontSize: "10px",
            letterSpacing: "0.4px",
            borderRadius: "4px",
            "& .MuiTooltip-arrow": {
              color: "#00ADFF"
            },
            bottom: -5
          }
        }
      }}
    >
      {children}
    </Tooltip>
  );
};

import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  carousellogoscard: css`
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    max-width: 345;
    border-radius: ${theme.spacing(2)};
    height: 100%;
  `,

  carousellogoscard__button: css`
    display: flex;
    flex-direction: column;
    align-items: inherit;
    flex: 1;
  `,
  carousellogoscard__media: css`
    height: 140px;
    object-fit: contain;
    background-size: auto;
    background-size: contain;
  `
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useState } from "react";
import Slider from "react-slick";

import { Box } from "@mui/material";

import { CarouselNavigation } from "../CarouselNavigation";
import { CarouselMainItem } from "./CarouselMainItem";
import { carouselMainResponsiveness, styles } from "./styles";
import { CarouselMainProps } from "./types";

export const CarouselMain: React.FC<CarouselMainProps> = ({ items, ...rest }) => {
  const sliderRef = useRef<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const handlePrevious = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const handleGoToSlide = (slideNumber: number) => {
    sliderRef.current?.slickGoTo(slideNumber);
  };

  return (
    <Box pt={{ xs: 3, md: 6 }} pb={2} {...rest} id="carousel-main">
      {/* @ts-ignore */}
      <Slider
        centerMode={true}
        dots={false}
        slidesToShow={1}
        slidesToScroll={1}
        swipe={true}
        focusOnSelect={true}
        css={styles.carouselmain}
        adaptiveHeight={false}
        useTransform={true}
        arrows={false}
        infinite={items?.length > 3}
        speed={800}
        centerPadding="25%"
        ref={sliderRef}
        initialSlide={0}
        beforeChange={(_, nextSlide) => {
          setCurrentSlide(nextSlide);
        }}
        responsive={carouselMainResponsiveness}
      >
        {items?.map(({ image, url }, index) => (
          <CarouselMainItem key={index} image={image} url={url} />
        ))}
      </Slider>

      <CarouselNavigation
        onNextClick={handleNext}
        onPreviousClick={handlePrevious}
        onDotClick={handleGoToSlide}
        slidesCount={items?.length ?? 0}
        currentSlide={currentSlide}
        justifyContent="center"
        mt={2}
        mb={1}
      />
    </Box>
  );
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef } from "react";
import Slider from "react-slick";

import { CartButton } from "@/components/Buttons/CartButton";
import { ContainerHeader } from "@/components/Container/ContainerHeader";
import { CarolselUtils, CarouselState } from "@/utils/carousel";
import { LinkUtils } from "@/utils/linkUtils";

import { CarouselNavigation } from "../CarouselNavigation";
import { PartnerCard } from "./PartnerCard";
import { carouselPartnersResponsiveness, styles } from "./styles";
import { CarouselPartnersProps, PartnerCardDetailsProps } from "./types";
import { CarouselNavigationRef } from "../CarouselNavigation/types";

export const CarouselPartners: React.FC<CarouselPartnersProps> = ({ items, title, subtitle, children }) => {
  const sliderRef = useRef<Slider>(null);
  const navigationRef = useRef<CarouselNavigationRef>(null);

  const onCardClick = ({ categoryId, partnerId, productId, link }: PartnerCardDetailsProps) => {
    if (link) {
      return LinkUtils.openLink(link);
    }

    LinkUtils.openProduct({ categoryId, partnerId, productIdOrExternalCode: productId });
  };

  const handlePrevious = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <>
      <ContainerHeader title={title} subtitle={subtitle}>
        <CarouselNavigation onNextClick={handleNext} onPreviousClick={handlePrevious} justifyContent="flex-end" ref={navigationRef} />
      </ContainerHeader>

      {/* @ts-ignore */}
      <Slider
        centerMode={false}
        dots={false}
        slidesToShow={5}
        slidesToScroll={1}
        swipe={true}
        focusOnSelect={false}
        css={styles.carouselpartners}
        adaptiveHeight={false}
        useTransform={true}
        arrows={false}
        infinite={false}
        speed={300}
        ref={sliderRef}
        responsive={carouselPartnersResponsiveness}
        onReInit={() => {
          try {
            if (!sliderRef.current?.innerSlider) {
              return;
            }

            /* @ts-ignore */
            const carouselState = sliderRef.current.innerSlider.state as CarouselState;

            const { disableNext, disablePrevious } = CarolselUtils.getNavigationState({ carouselState, nextSlide: carouselState?.targetSlide });

            if (!navigationRef.current) {
              return;
            }

            navigationRef.current.changeNavigation(disablePrevious, disableNext);
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {items?.map((item, index) => {
          const { partnerId, categoryId, productId, url, buttonUrl, type } = item;

          return (
            <PartnerCard key={index} onClick={() => onCardClick({ categoryId, partnerId, productId, link: url })} {...item}>
              <CartButton buttonType={type} buttonUrl={buttonUrl} categoryId={categoryId} partnerId={partnerId} productId={productId} cardUrl={url} />
            </PartnerCard>
          );
        })}

        {children}
      </Slider>
    </>
  );
};

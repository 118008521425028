import React from "react";

import { TooltipCustom } from "@/components/TooltipCustom";
import { IconButton, Stack } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";

import { styles } from "./styles";
import { ArrowButtonProps } from "./type";

export const ArrowButton: React.FC<ArrowButtonProps> = ({
  handleBack,
  handleNext,
  disableBackButton,
  disableNextButton,
  backNavigationSelector,
  nextNavigationSelector,
  ...props
}) => {
  return (
    <Stack flexDirection="row" gap={2} justifyContent="center" p={2}>
      <TooltipCustom title="Anterior">
        <IconButton disabled={disableBackButton} onClick={handleBack} {...props} id={backNavigationSelector} css={styles.button}>
          <ArrowLeft size={32} color="#ffff" />
        </IconButton>
      </TooltipCustom>

      <TooltipCustom title="Próximo">
        <IconButton disabled={disableNextButton} onClick={handleNext} {...props} id={nextNavigationSelector} css={styles.button}>
          <ArrowRight size={32} color="#ffff" />
        </IconButton>
      </TooltipCustom>
    </Stack>
  );
};
